import React from "react";
import { MdOutlineDoubleArrow } from "react-icons/md";

function GoBackBtn() {
  return (
    <>
      <div className="Back">
        <MdOutlineDoubleArrow className="Back-icon" />
        <MdOutlineDoubleArrow className="Back-icon" />
      </div>
    </>
  );
}

export default GoBackBtn;
